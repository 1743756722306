import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/application/a3b23589-e9d3-4321-9a52-8c7a30f3fb22
export default defineNuxtPlugin(({ isDev, $config }) => {
    if (isDev || !process.client) return;

    datadogRum.init({
        applicationId: 'c7a536ee-9b13-455a-94e7-d72ddbed032d',
        clientToken: 'pubba85aa3b81a7dc0dffc53b66ad8999fc',
        site: 'datadoghq.com',
        service: 'messaging',
        env: $config.DEPLOY_ENV,
        version: $config.VERSION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
});
