import { defineStore } from 'pinia';
import { IMessage, IMessageParticipant, INewMessage } from '~/types/IMessage';
import useParticipantStore from '~/store/participantStore';
import useSupplierOverviewStore from '~/store/supplierOverviewStore';
import useUserStore from '~/store/userStore';
import MESSAGE_TEMPLATES from '~/constants/message-templates';
import PROCESSING_FILE from '~/constants/processing-file';
import useNumberOfUnreadStore from './numberOfUnreadStore';
import { FileMetadata } from './attachmentStore';

const useMessageListStore = defineStore('messagesStore', {
    state: () => ({
        messages: [] as IMessage[],
        loadEmptyState: false as boolean,
        cancelToken: null as any,
        templateKey: '' as string,
        inputMessage: '' as string,
        readonly: false as boolean,
    }),
    getters: {
        getMessages: (state) => state.messages,
        getMessagesLen: (state) => state.messages.length,
        getLoadEmptyState: (state) => state.loadEmptyState,
        getReadOnlyFlag: (state) => state.readonly,
    },
    actions: {
        clearMessages(): void {
            this.setMessages([]);
            this.unsetLoadEmptyState();
        },
        setLoadEmptyState(): void {
            this.loadEmptyState = true;
        },
        unsetLoadEmptyState(): void {
            this.loadEmptyState = false;
        },
        setMessages(newMessages: any[]): void {
            this.messages = newMessages;
        },
        setTemplateKey(templateKey: string): void {
            this.templateKey = templateKey;
        },
        setReadOnlyFlag(): void {
            this.readonly = true;
        },
        /**
         * function that populate placeholders for template message body and return prepared message to set in the form
         * @param {string} senderId - uuid of the user that is sender of the meesage
         * @param {string} templateKey - key string of the message template which has prepared message body
         */
        setInputMessage(userSender: IMessageParticipant) {
            const supplierOverviewStore = useSupplierOverviewStore();
            const participantStore = useParticipantStore();

            const messageParticipants = { ...participantStore.getActiveParticipants };
            delete messageParticipants[userSender.user_id];
            const participantsArray = Object.values(messageParticipants);
            const recipient = participantsArray[0].sender_first_name || '';

            // Define the values for the placeholders
            const values = {
                supplier_name: supplierOverviewStore.getSupplierDetails?.name || '',
                sender_name: `${userSender.sender_first_name || ''} ${userSender.sender_last_name || ''}`,
                recipient_name: recipient || '',
            };

            // Get the templates object
            const templates = MESSAGE_TEMPLATES().templates as {
                [key: string]: {
                    body: string;
                    cursor_after?: string;
                };
            };

            // Access the template body using the templateKey
            let templateBody = templates[this.templateKey]?.body || '';

            // Replace placeholders with actual values using forEach
            Object.entries(values).forEach(([key, value]) => {
                if (typeof value === 'string') {
                    const regex = new RegExp(`{${key}}`, 'g');
                    templateBody = templateBody.replace(regex, value);
                }
            });

            this.inputMessage = templateBody;
        },
        /**
         * function that will execute request to get messages for given thread and save it in the store.
         * @param {string} threadId - uuid of thread that we want to load messages - we should get value from `useThreadStore` as `getActiveThread`
         * @param {bool} loadMore - to load more message for current thread, used to caculate offset for pagination - also will drop existing or concanate on existing message list returned result
         */
        async fetchMessages(threadId: string, loadMore: boolean = false): Promise<void> {
            const participantStore = useParticipantStore();
            await participantStore.fetchAllParticipantsForThread(threadId);

            if (this.cancelToken) {
                this.cancelToken.cancel();
            }

            const source = this.$nuxt.$axios.CancelToken.source();
            this.cancelToken = source;

            let offset = 0;
            if (loadMore) {
                offset = this.messages.length;
            }

            try {
                // Workaround for larger screens (4k) to load more messages
                // so we can have y-scrollbar rendered and fetch more messages on scroll top
                const windowSize = window.matchMedia('(min-width: 3840px)');
                let limit = 10;
                if (windowSize.matches) {
                    limit = 20;
                }
                let messagesResponse;
                if (!this.getReadOnlyFlag) {
                    messagesResponse = await this.$nuxt.$axios.get(`/api/v1/threads/${threadId}/messages`, {
                        cancelToken: source.token,
                        params: {
                            offset,
                            limit,
                        },
                    });
                } else {
                    const userStore = useUserStore();
                    const userId = userStore.getUserId;
                    messagesResponse = await this.$nuxt.$axios.get(`/api/v1/threads/${threadId}/messages/read-only`, {
                        cancelToken: source.token,
                        params: {
                            offset,
                            limit,
                            user_id: userId,
                        },
                    });
                }

                if (messagesResponse.data.length) {
                    let messageList = messagesResponse.data.reverse();

                    messageList = messageList.map((msg: IMessage) => ({
                        ...msg,
                        sender_data: participantStore.getParticipantByUUID(msg.sender_id),
                    }));

                    if (loadMore) {
                        messageList = [...messageList, ...this.messages];
                    }
                    this.setMessages(messageList);
                    this.cancelToken = null;
                }
                this.setLoadEmptyState();

                if (this.templateKey) {
                    const userStore = useUserStore();
                    const userId = userStore.getUserId;
                    if (userId != null) {
                        const userSender = participantStore.getParticipantByUUID(userId);
                        this.setInputMessage(userSender);
                    }
                }
            } catch (err) {
                console.error(`Issues with sending request. ${err}`);
            }
        },
        /**
         * function that send message and add message into store
         * @param {INewMessage} message - message object that should be send with attachments
         * @param {string} senderId - uuid of the user that is sender of the meesage
         * @param {Record<string, FileMetadata>} attachments - map of the attachments, need just to display pleasent text to the user
         * @param {IThreadItem} thread - active thrad which will get new message text body displayed after sent message
         */
        async sendMessage(
            message: INewMessage,
            senderId: string,
            attachments: Record<string, FileMetadata>,
        ): Promise<IMessage | null> {
            const participantStore = useParticipantStore();
            const numberOfUnreadStore = useNumberOfUnreadStore();

            try {
                const hasAttachments = !!Object.keys(attachments).length;

                const messagesResponse = await this.$nuxt.$axios.post(`/api/v1/users/${senderId}/messages`, message, {
                    params: { hasAttachments },
                });
                const sender_data = participantStore.getParticipantByUUID(messagesResponse.data.sender_id);
                messagesResponse.data.sender_data = sender_data;

                messagesResponse.data.attachments = Object.keys(attachments).map((key) => ({
                    ...attachments[key],
                    fileUrl: PROCESSING_FILE,
                }));
                numberOfUnreadStore.setOldNumberOfUnread(0);
                return messagesResponse.data;
            } catch (err) {
                console.error(`Issues with sending request. ${err}`);
            }
            return null;
        },
    },
});

export default useMessageListStore;
