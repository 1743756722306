import { defineStore } from 'pinia';
import { IThreadItem } from '~/types/threadItem';
import useUserStore from '~/store/userStore';
import useQuoteDetailStore from '~/store/quoteDetailsStore';
import useSearchThreadsStore from '~/store/searchThreadsStore';
import useCustomerOverviewStore from './customerOverviewStore';
import useSupplierOverviewStore from './supplierOverviewStore';
import useDisplayLoadMoreThreads from './displayLoadMoreThreads';
import useMessageListStore from './messageListStore';
import useNumberOfUnreadStore from './numberOfUnreadStore';

const useThreadStore = defineStore('threadStore', {
    state: () => ({
        threads: [] as IThreadItem[],
        selectedActiveThread: { threadId: '' } as IThreadItem,
        activeThreadParticipantsListAsString: '' as string,
        preloadedThreadId: '' as string,
        threadLoadingLimit: 10 as number,
        oldUnreadNumber: 0 as number,
        showRmqMessage: false as boolean,
    }),
    getters: {
        getThreads: (state) => state.threads,
        getActiveThread: (state) => state.selectedActiveThread,
        getActiveThreadParticipantsListAsString: (state) => state.activeThreadParticipantsListAsString,
        getNumberOfLoadedThreads: (state) => state.threads.length,
        getThreadLoadingLimit: (state) => state.threadLoadingLimit,
        getShowRmqState: (state) => state.showRmqMessage,
    },
    actions: {
        async resetNumberOfUnreadMessagesInThread(threadId: string): Promise<any> {
            await this.$nuxt.$axios.$patch(`/api/v1/threads/${threadId}/reset-unread-messages`);
        },
        async fetchThreadByUUID(uuid: string, true_number_of_unread: boolean = false): Promise<any> {
            const userStore = useUserStore();
            const response = await this.$nuxt.$axios.$get(`/api/v1/users/threads/${uuid}`, {
                params: { user_id: userStore.getUserId },
            });

            const infoEndpoint: string = userStore.getUserType === 'supplier' ? 'consumer-info' : 'installer-info';
            const threadInfo = await this.$nuxt.$axios.$get(`/api/v1/threads/${response.thread_id}/${infoEndpoint}`, {
                params: { user_id: userStore.getUserId },
            });

            const threadDescription =
                userStore.getUserType === 'supplier'
                    ? `${threadInfo.property.address}, ${threadInfo.property.city}, ${threadInfo.property.state}`
                    : threadInfo.supplier.name;

            if (true_number_of_unread) {
                return {
                    threadId: response.thread_id,
                    lastMessageTime: response.last_message_time,
                    lastMessage: response.last_message_text,
                    numberOfUnreadMessages: response.number_of_unread_messages,
                    threadDesc: threadDescription,
                    participants: threadInfo.participants,
                    property: threadInfo.property,
                    muted: threadInfo.muted,
                };
            }
            return {
                threadId: response.thread_id,
                lastMessageTime: response.last_message_time,
                lastMessage: response.last_message_text,
                numberOfUnreadMessages: 0,
                threadDesc: threadDescription,
                participants: threadInfo.participants,
                property: threadInfo.property,
                muted: threadInfo.muted,
            };
        },
        async fetchThreadByUUIDReadOnly(uuid: string): Promise<any> {
            const userStore = useUserStore();
            const response = await this.$nuxt.$axios.$get(`/api/v1/threads/${uuid}/read-only`, {
                params: { user_id: userStore.getUserId },
            });
            const infoEndpoint: string = 'consumer-info';
            const threadInfo = await this.$nuxt.$axios.$get(`/api/v1/threads/${response.thread_id}/${infoEndpoint}`, {
                params: { user_id: userStore.getUserId },
            });

            const threadDescritpition = `${threadInfo.property.address}, ${threadInfo.property.city}, ${threadInfo.property.state}`;
            return {
                threadId: response.thread_id,
                lastMessageTime: '',
                lastMessage: '',
                numberOfUnreadMessages: 0,
                threadDesc: threadDescritpition,
                participants: threadInfo.participants,
                property: threadInfo.property,
            };
        },
        async toggleNotificationPreferenceForThread(thread_uuid: string): Promise<void> {
            await this.$nuxt.$axios.$post(`/api/v1/threads/${thread_uuid}/toggle-notification-preference`);
            const foundThread = this.threads.find((thread) => thread.threadId === thread_uuid) as IThreadItem;
            foundThread.muted = !foundThread.muted;
        },
        async fetchThreadsFromService(offset: any = null): Promise<void> {
            const userStore = useUserStore();
            const displayLoadMoreThreads = useDisplayLoadMoreThreads();
            const fetchOffset: number = offset === null ? this.getNumberOfLoadedThreads : offset;
            const response = await this.$nuxt.$axios.$get(`/api/v1/users/threads`, {
                params: {
                    user_id: userStore.getUserId,
                    offset: fetchOffset,
                    limit: this.getThreadLoadingLimit,
                },
            });
            const infoEndpoint: string = userStore.getUserType === 'supplier' ? 'consumer-info' : 'installer-info';

            if (response.user_requested_rmq === true) {
                this.showRmqMessage = response.user_requested_rmq;
                this.$nuxt.$axios.put(`/api/v1/users/${userStore.getUserId}/update-rmq-status`);
            }
            const loadedThreads = await Promise.all(
                response.threads.map(async (element: any) => {
                    const threadInfo = await this.$nuxt.$axios.$get(
                        `/api/v1/threads/${element.thread_id}/${infoEndpoint}`,
                        {
                            params: { user_id: userStore.getUserId },
                        },
                    );
                    const threadDescription =
                        userStore.getUserType === 'supplier'
                            ? `${threadInfo.property.address}, ${threadInfo.property.city}, ${threadInfo.property.state}`
                            : threadInfo.supplier.name;
                    return {
                        threadId: element.thread_id,
                        lastMessageTime: element.last_message_time,
                        lastMessage: element.last_message_text,
                        numberOfUnreadMessages: element.number_of_unread_messages,
                        threadDesc: threadDescription,
                        participants: threadInfo.participants,
                        property: threadInfo.property,
                        muted: threadInfo.muted,
                    };
                }),
            );

            loadedThreads.forEach((th) => {
                if (th.threadId !== this.preloadedThreadId) {
                    this.threads.push(th);
                }
            });

            if (loadedThreads.length < this.getThreadLoadingLimit) {
                displayLoadMoreThreads.unsetDisplayLoadMoreThreads();
            }
        },
        async searchThreads(
            searchBody: string,
            read: boolean | null,
            unread: boolean | null,
            sortBy: string,
            replied: boolean | null,
            triggeredByLoadMore: boolean = false,
            offset: number = 0,
        ): Promise<void> {
            const userStore = useUserStore();
            const messagesStore = useMessageListStore();
            const searchThreadsStore = useSearchThreadsStore();
            const displayLoadMoreThreads = useDisplayLoadMoreThreads();

            if (!triggeredByLoadMore) {
                // Do not clear threads when search is triggered trough Load More buttton
                messagesStore.clearMessages();
                this.clearThreads();
            }

            displayLoadMoreThreads.unsetDisplayLoadMoreThreads();

            searchThreadsStore.setSearchApplied();
            let readStatus = null;
            if (read && unread) {
                readStatus = null;
                searchThreadsStore.setReadApplied();
                searchThreadsStore.setUnreadApplied();
            } else if (!read && !unread) {
                readStatus = null;
                searchThreadsStore.unsetReadApplied();
                searchThreadsStore.unsetUnreadApplied();
            } else if (read) {
                readStatus = 'read';
                searchThreadsStore.setReadApplied();
                searchThreadsStore.unsetUnreadApplied();
            } else {
                readStatus = 'unread';
                searchThreadsStore.unsetReadApplied();
                searchThreadsStore.setUnreadApplied();
            }

            if (replied) {
                searchThreadsStore.setRepliedApplied();
            } else {
                searchThreadsStore.unsetRepliedApplied();
            }

            const response = await this.$nuxt.$axios.get(`/api/v1/search/`, {
                params: {
                    user_id: userStore.getUserId,
                    read_status: readStatus,
                    search_term: searchBody,
                    sort_by: sortBy,
                    customer_has_replied: replied,
                    offset,
                    limit: this.getThreadLoadingLimit,
                },
            });

            const infoEndpoint: string = userStore.getUserType === 'supplier' ? 'consumer-info' : 'installer-info';

            const loadedThreads = await Promise.all(
                response.data.items.map(async (element: any) => {
                    const threadInfo = await this.$nuxt.$axios.$get(
                        `/api/v1/threads/${element.thread_id}/${infoEndpoint}`,
                        {
                            params: { user_id: userStore.getUserId },
                        },
                    );
                    const threadDescription =
                        userStore.getUserType === 'supplier'
                            ? `${threadInfo.property.address}, ${threadInfo.property.city}, ${threadInfo.property.state}`
                            : threadInfo.supplier.name;
                    return {
                        threadId: element.thread_id,
                        lastMessageTime: element.last_message_time,
                        lastMessage: element.last_message_text,
                        numberOfUnreadMessages: element.number_of_unread_messages,
                        threadDesc: threadDescription,
                        participants: threadInfo.participants,
                        property: threadInfo.property,
                    };
                }),
            );

            loadedThreads.forEach((th: any) => {
                this.threads.push(th);
            });

            displayLoadMoreThreads.setDisplayLoadMoreThreads();
            if (loadedThreads.length < this.getThreadLoadingLimit) {
                displayLoadMoreThreads.unsetDisplayLoadMoreThreads();
            }
        },

        clearThreads() {
            this.threads = [];
        },

        setActiveThreadParticipantsListAsString(str: string) {
            this.activeThreadParticipantsListAsString = str;
        },

        setSingleThread(thread: IThreadItem) {
            this.threads = [thread, ...this.threads];
        },

        setPreloadedThread(threadId: string) {
            this.preloadedThreadId = threadId;
        },

        formatParticipantAsStringForList(participant: any) {
            const { first_name, last_name, phone_number } = participant;

            if (phone_number) {
                return `${first_name} ${last_name} (${phone_number})`;
            }
            return `${first_name} ${last_name}`;
        },

        setActiveThread(active_thread: IThreadItem) {
            const customerOverviewDetailStore = useCustomerOverviewStore();
            const supplierOverviewStore = useSupplierOverviewStore();
            const quoteDetailStore = useQuoteDetailStore();
            const userStore = useUserStore();
            const messagesStore = useMessageListStore();
            const numberOfUnreadStore = useNumberOfUnreadStore();

            this.selectedActiveThread = active_thread;
            numberOfUnreadStore.setOldNumberOfUnread(this.selectedActiveThread.numberOfUnreadMessages);
            this.selectedActiveThread.numberOfUnreadMessages = 0;

            messagesStore.fetchMessages(this.getActiveThread.threadId);
            if (userStore.getUserType === 'supplier') {
                this.setActiveThreadParticipantsListAsString(
                    active_thread.participants
                        .filter((participant) => participant.account_type !== 'supplier')
                        .map((participant) => `${participant.first_name} ${participant.last_name}`)
                        .join(', '),
                );
                customerOverviewDetailStore.fetchCustomerOverviewDetails(active_thread.threadId);
            } else {
                this.setActiveThreadParticipantsListAsString(
                    active_thread.participants
                        .map((participant) => this.formatParticipantAsStringForList(participant))
                        .join(', '),
                );

                supplierOverviewStore.fetchSupplierDetails(active_thread.threadId);
                quoteDetailStore.fetchQuoteDetails(active_thread.threadId);
            }
        },
        async addOneParticipantToThread(user_uuid: string) {
            // user_uuid: uuid of rep to be added to thread
            const userStore = useUserStore();
            await this.$nuxt.$axios.post(
                `/api/v1/threads/${this.getActiveThread.threadId}/participant/${user_uuid}?user_id=${userStore.getUserId}`,
            );
        },
        async addMultipleParticipantsToThread(users: Array<any>) {
            const userStore = useUserStore();

            await Promise.all(
                users.map(async (user: any) => {
                    await this.$nuxt.$axios.post(
                        `/api/v1/threads/${this.getActiveThread.threadId}/participant/${user.userUuid}?user_id=${userStore.getUserId}`,
                    );
                }),
            );
        },
    },
});

export default useThreadStore;
