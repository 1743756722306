
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { analyticsMixin } from '@/mixins/analytics';
import { EsNavBarLoggedIn, EsFooter, getEsFooterContent } from '@energysage/es-nav';
import { track } from '../api/api';

export default defineComponent({
    name: 'DefaultLayout',
    components: {
        EsFooter,
        EsNavBarLoggedIn,
        // TODO: Unknown custom element: <es-nav-bar-link> - did you register the component correctly? For recursive components, make sure to provide the "name" option.
    },
    mixins: [analyticsMixin],
    computed: {
        footerContent() {
            const footerContent = getEsFooterContent();

            // update home link to root-relative url
            footerContent.home.link = process.env.ES_DOMAIN!;

            return footerContent;
        },
    },
    async created() {
        const context = useContext();
        track('message-center-visited', context);
    },
});
